<template>
    <div class="week-report">
        <div class="week-report-content">
            <div class="week-report-title">
                <div class="table-title">推荐面试情况({{selectMonth | formatDate("YYYY年MM月")}})</div>
                <div class="week-report-top">
                    <!-- 时间段选择 -->
                    <el-date-picker
                        :clearable="false"
                        :picker-options="pickerOptions"
                        @change="handleDateChange"
                        placeholder="选择月份"
                        size="small"
                        type="month"
                        v-model="selectMonth"
                    ></el-date-picker>

                    <!-- 成员选择 -->
                    <div
                        :class="teamMemberPopoverVisible ? 'triangle-drop-up' : 'triangle-drop-down'"
                        class="employee-select-wrap triangle-drop"
                    >
                        <el-popover
                            placement="bottom-end"
                            popper-class="team-member-popover week-report-team-member-popover"
                            trigger="click"
                            v-model="teamMemberPopoverVisible"
                            width="700"
                        >
                            <team-member-select
                                :date="dateRange"
                                :isAllMembers="true"
                                :isHistoryData="true"
                                :isShowDimission="false"
                                :selected="userIds"
                                :visible="teamMemberPopoverVisible"
                                @item-select-cb="employeeSelect"
                                ref="teamSelect"
                            ></team-member-select>
                            <div
                                class="employee-select-text"
                                slot="reference"
                            >{{ selectedMembersText }}</div>
                        </el-popover>
                    </div>

                    <el-button
                        :loading="exportLoading"
                        @click="handleExport"
                        class="export"
                        type="primary"
                    >导出明细</el-button>
                </div>
            </div>

            <div
                class="table-wrap"
                v-loading="tableLoading"
            >
                <el-table
                    :cell-class-name="tableCellClassName"
                    :data="tableData"
                    :header-cell-class-name="tableHeaderCellClassName"
                    :row-class-name="tableRowClassName"
                    border
                    style="width: 100%"
                >
                    <el-table-column
                        :key="'column_' + index"
                        :label="item.label"
                        :prop="item.prop"
                        :width="item.width"
                        v-for="(item, index) in reportTableTitle"
                    >
                        <template slot-scope="{row}">
                            <template v-if="item.prop == 'recommendTime'">
                                <template
                                    v-if="row[item.prop] !='合计'"
                                >{{row['startDate'] | formatDate("YY.MM.DD")}}-{{row['endDate'] | formatDate("YY.MM.DD")}}</template>
                                <template v-else>合计</template>
                            </template>
                            <template
                                v-else-if="item.label.includes('-')"
                            >{{getInterviewsCell(item.label, row, index)}}</template>
                            <template v-else-if="item.prop == 'rate'">{{getRate(row)}}</template>
                            <template v-else>{{row[item.prop]}}</template>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import {
    weekReportTableTitle,
} from '#/js/config/dates/weekReport.js';
import {
    recommendedInterviewList,
    recommendedInterviewExport,
} from '#/js/service/statisticsService.js';
import {
    downloadFile,
} from '#/js/util/fileUtil.js';
import TeamMemberSelect from "#/component/common/team-member-select.vue";

const startDate = moment().subtract(0, "months").format("YYYY-MM") + "-01";
const endDate = moment(startDate)
    .add(1, "month")
    .subtract(1, "days")
    .format("YYYY-MM-DD");


export default {
    name: 'week-report',
    components: {
        TeamMemberSelect,
    },
    data () {
        return {
            selectMonth: new Date(),
            pickerOptions: {
                disabledDate (time) {
                    return moment(time).isBefore(moment().subtract(6, 'months')) || moment(time).isAfter(moment());
                },
            },

            teamMemberPopoverVisible: false,
            selectedMembersText: "全部成员",
            dateRange: [startDate, endDate],
            userIds: [],
            endDate: endDate,

            tableLoading: false,
            exportLoading: false,

            reportTableTitle: [],

            originData: [],
            tableData: [],
        };
    },
    created () { },
    mounted () {
        this.$refs.teamSelect.getHistoryTeamData(true);

        this.getTableData();
    },
    methods: {
        handleDateChange (value) {
            console.log(value);
            this.selectMonth = value;

            const startDate = moment(value).subtract(0, "months").format("YYYY-MM") + "-01";
            const endDate = moment(startDate)
                .add(1, "month")
                .subtract(1, "days")
                .format("YYYY-MM-DD");
            this.dateRange = [startDate, endDate];
            this.endDate = endDate;
            console.log(this.dateRange);

            this.$refs.teamSelect.getHistoryTeamData(true);
        },

        // 导出数据
        handleExport () {
            const params = {
                startDate: this.endDate,
                userIds: this.userIds.length == 0 ? null : this.userIds,
            };

            this.exportLoading = true;
            recommendedInterviewExport(params)
                .then(res => {
                    console.log(`erport:`, res);
                    downloadFile(res);
                })
                .finally(() => {
                    this.exportLoading = false;
                });
        },

        employeeSelect (userIds, text) {
            console.log(`employeeSelect:`, userIds, text);
            this.userIds = userIds;
            this.selectedMembersText = text;
            this.getTableData();
            this.teamMemberPopoverVisible = false;
        },


        getTableData () {
            const params = {
                startDate: this.endDate,
                userIds: this.userIds.length == 0 ? null : this.userIds,
            };

            this.tableLoading = true;
            recommendedInterviewList(params)
                .then(res => {
                    console.log(`recommendedInterviewList:`, res);
                    this.originData = res;

                    this.generateTableTitle();
                    this.transformData();
                })
                .finally(() => {
                    this.tableLoading = false;
                });
        },

        getTotalCount (monthData) {
            let tempNextinterviews = [];
            for (let i = 0; i < monthData[0].interviews.length; i++) {
                let interviewCount = 0;
                for (let j = 0; j < monthData.length; j++) {
                    interviewCount += (monthData[j].interviews[i].interviewCount || 0);
                }
                tempNextinterviews.push(interviewCount);
            }
            return tempNextinterviews;
        },

        transformData () {
            const _preMonth = this.originData.filter(it => it.month == this.originData[0].month);
            const _nextMonth = this.originData.filter(it => it.month != this.originData[0].month);

            const preTotal = {
                recommendTime: '合计',
                recommendationCount: eval(_preMonth.filter(it => it.recommendationCount).map(it => it.recommendationCount).join("+")),
                interviewTotalCount: eval(_preMonth.filter(it => it.interviewTotalCount).map(it => it.interviewTotalCount).join("+")),

                interviews: this.getTotalCount(_preMonth),
            };
            const nextTotal = {
                recommendTime: '合计',
                recommendationCount: eval(_nextMonth.filter(it => it.recommendationCount).map(it => it.recommendationCount).join("+")),
                interviewTotalCount: eval(_nextMonth.filter(it => it.interviewTotalCount).map(it => it.interviewTotalCount).join("+")),

                interviews: this.getTotalCount(_nextMonth),
            };

            this.tableData = [..._preMonth, preTotal, ..._nextMonth, nextTotal];
            console.log(this.tableData);
        },

        generateTableTitle () {
            const dynamicTitle = this.originData.map((it, index) => {
                return {
                    label: moment(it.startDate).format("YY.MM.DD") + "-" + moment(it.endDate).format("YY.MM.DD"),
                    width: '150',
                };
            });
            this.reportTableTitle = weekReportTableTitle.concat(dynamicTitle.reverse());
        },

        getInterviewsCell (label, row, idx) {
            const { interviews, recommendTime, } = row;
            if (recommendTime == "合计") {
                return interviews[idx - 4];
            } else {
                return interviews[idx - 4]?.interviewCount;
                // return interviews?.find(it => label == (moment(it.startDate).format("YYYY.MM.DD") + "-" + moment(it.endDate).format("YYYY.MM.DD")))?.interviewCount;
            }
        },

        getRate (row) {
            // if (row.recommendationCount && row.interviewTotalCount) {
            let _rate = ((row.interviewTotalCount || 0) / (row.recommendationCount || 1)) * 100;
            if (String(_rate).includes(".")) {
                _rate = _rate.toFixed(2);
            }
            return _rate + '%';
            // }
        },

        formatCell (row, type) {
            if (type == 'rate') {
                if (row["name"] && row["address"]) {
                    return (row["name"] / row["address"] * 100).toFixed(2) + '%';
                } else {
                    return '';
                }
            }
        },

        tableRowClassName ({ row, rowIndex }) {
            if (row.recommendTime == '合计') {
                return 'success-row';
            }
            return '';
        },

        tableCellClassName ({ row, column, rowIndex, columnIndex }) {
            if (!row.recommendTime && column.label.includes('-')) {
                const shortTimeArr = row.interviews[columnIndex - 4].endDate;
                if (moment(shortTimeArr).isBefore(moment(row.startDate))) {
                    return 'gray-cell';
                }
            }
            return '';
        },

        tableHeaderCellClassName () {
            return 'header-row';
        },

    },
}
</script>

<style lang="scss" scope>
.week-report {
    padding: 20px;
    height: 100%;
    overflow-y: auto;
    &-content {
        padding-bottom: 38px;
        background-color: #fff;
        border-radius: 8px;
        min-height: calc(100vh - 90px);
        .table-wrap {
            padding: 10px 20px;
        }
    }

    &-title {
        display: flex;
        justify-content: space-between;
        line-height: 32px;
        padding: 30px 30px 0;
        margin-bottom: 8px;

        .table-title {
            font-size: 28px;
            color: #666;
            line-height: 32px;
        }
    }
    &-top {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;

        position: relative;
        text-align: center;
        .el-select.select-client {
            &:hover {
                .el-input__inner {
                    background: #38bc9d;
                    border-color: #38bc9d;
                    color: #fff;
                    &::placeholder {
                        color: #fff;
                    }
                }
            }
            input.el-input__inner {
                width: 118px;
                height: 32px;
                // border-radius: 16px;
                // &:hover {
                //     background-color: #38bc9c;
                // }
            }
            .el-icon-arrow-up:before {
                display: none;
            }
        }
        .export {
            // position: absolute;
            // right: 0;
            margin-left: 20px;
            border-radius: 4px;
            height: 32px;
        }

        .employee-select-wrap {
            position: relative;
            margin-left: 20px;
            .employee-select-text {
                display: inline-block;
                width: 116px;
                height: 32px;
                line-height: 32px;
                border: 1px solid #d3d3d3;
                // border-radius: 16px;
                padding: 0 36px 0 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                vertical-align: top;
            }
            &:hover {
                .employee-select-text {
                    background: #38bc9d;
                    border-color: #38bc9d;
                    color: #fff;
                }
            }
            &.triangle-drop-up {
                &::after {
                    border-bottom: 12px solid #fff;
                    margin-top: -3px;
                }
                .employee-select-text {
                    background: #38bc9d;
                    border-color: #38bc9d;
                    color: #fff;
                }
            }
        }

        .triangle-drop {
            &:after {
                position: absolute;
                display: block;
                content: '';
                top: 50%;
                transform: translateY(-50%);
                right: 16px;
                pointer-events: none;
            }
            &-down {
                &:after {
                    border-top: 12px solid #ddd;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                }
                &:hover {
                    &:after {
                        border-top: 12px solid #fff;
                    }
                }
            }
            &-up {
                &:after {
                    border-bottom: 12px solid #ddd;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                }
                &:hover {
                    &:after {
                        border-bottom: 12px solid #fff;
                    }
                }
            }
        }
    }
}

.el-table .warning-row {
    background: oldlace;
}

.el-table .success-row {
    background: #ecf9f6;
}
.el-table .gray-cell {
    background: #f3f3f3;
}
.header-row {
    background: #ffecdf !important;
    font-size: 16px;
    font-weight: normal;
    color: #333;
}
</style>