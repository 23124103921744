export const weekReportTableTitle = [
    {
        prop: 'recommendTime',
        label: '推荐时间',
        width: '180',
    },
    {
        prop: 'recommendationCount',
        label: '推荐数',
        width: '150',
    },
    {
        prop: 'interviewTotalCount',
        label: '当月/当周推荐累计产生面试',
        width: '150',
    },
    {
        prop: 'rate',
        label: '面试率(%)',
        width: '150',
    },
];