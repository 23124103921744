import { statistics as statisticsUrl } from "@/js/config/api.json";

// 周报的table date
export const recommendedInterviewList = (params) => {
    return _request({
        method: 'POST',
        baseURL: 'LbdJavaApi',
        javaProject: 'performance',
        url: statisticsUrl.recommended_interview_list,
        data: params,
    });
};

// 周报的导出
export const recommendedInterviewExport = (params) => {
    return _request({
        method: 'POST',
        baseURL: 'LbdJavaApi',
        javaProject: 'performance',
        responseType: 'blob',
        url: statisticsUrl.recommended_interview_export,
        data: params,
    });
};